// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apps_other_____fwVRJ{display:flex;flex-wrap:wrap;gap:4rem;padding:4rem 0}.apps_other__text_container__TVvyh{flex:1 1 43.2rem}.apps_other__text_container__TVvyh p{max-width:40rem}.apps_other__title__CV5qj{margin-bottom:2.4rem}.apps_other__links_container__rJmH7{flex:1;display:flex;justify-content:flex-end;gap:2rem;padding-top:1rem}@media(max-width: 767px){.apps_other_____fwVRJ{padding:1.4rem 0}.apps_other__links_container__rJmH7{flex-wrap:wrap}.apps_other__title__CV5qj{font-size:1.6rem;line-height:2rem;margin-bottom:2.1rem}}`, "",{"version":3,"sources":["webpack://./src/pages/home_page/apps_other/AppsOther.module.scss"],"names":[],"mappings":"AACA,sBACE,YAAA,CACA,cAAA,CACA,QAAA,CACA,cAAA,CAGF,mCACE,gBAAA,CAEA,qCACE,eAAA,CAIJ,0BACE,oBAAA,CAIF,oCACE,MAAA,CACA,YAAA,CACA,wBAAA,CACA,QAAA,CACA,gBAAA,CAGF,yBACE,sBACE,gBAAA,CAGF,oCACE,cAAA,CAGF,0BACE,gBAAA,CACA,gBAAA,CACA,oBAAA,CAAA","sourcesContent":["@import \"styles/mixins.scss\";\n._ {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 4rem;\n  padding: 4rem 0;\n}\n\n.text_container {\n  flex: 1 1 43.2rem;\n\n  p {\n    max-width: 40rem;\n  }\n}\n\n.title {\n  margin-bottom: 2.4rem;\n}\n\n\n.links_container {\n  flex: 1;\n  display: flex;\n  justify-content: flex-end;\n  gap: 2rem;\n  padding-top: 1rem;\n}\n\n@media(max-width: 767px) {\n  ._ {\n    padding: 1.4rem 0;\n  }\n\n  .links_container {\n    flex-wrap: wrap;\n  }\n\n  .title {\n    font-size: 1.6rem;\n    line-height: 2rem;\n    margin-bottom: 2.1rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": `apps_other_____fwVRJ`,
	"text_container": `apps_other__text_container__TVvyh`,
	"title": `apps_other__title__CV5qj`,
	"links_container": `apps_other__links_container__rJmH7`
};
export default ___CSS_LOADER_EXPORT___;
