// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descriptions_____NMqsq{padding:4.3rem 0}.descriptions__list__jR326{display:flex;flex-wrap:wrap;gap:4.4rem 2.4rem}.descriptions__list__jR326>*{flex:1 1 40rem}@media(max-width: 767px){.descriptions_____NMqsq{padding:1rem 0}.descriptions__list__jR326{gap:3.2rem}}`, "",{"version":3,"sources":["webpack://./src/pages/home_page/descriptions/Descriptions.module.scss"],"names":[],"mappings":"AACA,wBACE,gBAAA,CAGF,2BACE,YAAA,CACA,cAAA,CACA,iBAAA,CAEA,6BACE,cAAA,CAIJ,yBACE,wBACE,cAAA,CAGF,2BACE,UAAA,CAAA","sourcesContent":["@import \"styles/mixins.scss\";\n._ {\n  padding: 4.3rem 0;\n}\n\n.list {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 4.4rem 2.4rem;\n\n  > * {\n    flex: 1 1 40rem;\n  }\n}\n\n@media(max-width: 767px) {\n  ._ {\n    padding: 1rem 0;\n  }\n\n  .list {\n    gap: 3.2rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": `descriptions_____NMqsq`,
	"list": `descriptions__list__jR326`
};
export default ___CSS_LOADER_EXPORT___;
