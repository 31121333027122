import cn from "classnames";
import s from "./terms_of_use/TermsOfUse.module.scss";


function TermsOfUse() {
    return (
        <main className={cn(s._, 'wrapper')}>
            <h3 className={s.title}>
                ПОЛИТИКА КОНФИДЕНЦИАЛЬ
                <wbr/>
                НОСТИ
            </h3>

            <div className="text">

                <p>Azur Games ценит безопасность ваших персональных данных. Данная Политика Конфиденциальности регламентирует взаимодействие Azur Interactive Games Limited, далее по тексту “Azur Games”, “мы”, с вашими персональными данными во время
                    использования наших игр “Сервисы”, в том числе их сбор, использование и методы обработки.</p>

                <p>Вы осознаете и соглашаетесь, что Azur Games оставляет за собой право обновлять, вносить изменения или правки в данную Политику Конфиденциальности, все изменения вступают в силу незамедлительно после публикации на веб-сайте,
                    располагающемся по адресу azurgames.com. Мы рекомендуем вам проверять azurgames.com время от времени на наличие опубликованных изменений в данную Политику Конфиденциальности.</p>


                <p>Используя любые Сервисы, предоставляемые Azur Games, вы соглашаетесь на сбор и обработку ваших персональных данных в соответствии с данной Политикой Конфиденциальности. Если вы не согласны с условиями и/или положениями данной
                    Политики Конфиденциальности, пожалуйста, не предоставляйте ваши персональные данные и не используйте Сервисы Azur Games.</p>


                <p><strong>1. Какую информацию мы собираем и способы ее сбора</strong></p>

                Данные, предоставляемые вами:

                <ul>
                    <li>Контактные данные, предоставляемые вами внутри Сервисов, такие как никнейм и адрес электронной почты
                        История вашей переписки со службой поддержки Сервисов, в том числе ваши обращения и/или логи внутриигровых чатов
                    </li>
                    <li>Данные, собираемые автоматически нами или нашими сервисными провайдерами:</li>
                    <li>Данные о вашем игровом прогрессе и аккаунте</li>
                    <li>Данные о вашем использовании Сервисов, в том числе любые игровые данные и ваши взаимодействия с другими игроками (пользователями) внутри Сервисов</li>
                    <li>Данные о вашем устройстве, такие как имя устройства, модель устройства, операционная система, разрешение экрана, тип браузера, страна использования и языковые настройки устройства, информация об интернет провайдере и/или
                        провайдере сотовой связи, данные о сети
                    </li>
                    <li>Ваш IP адрес и идентификаторы мобильного устройства, в том числе рекламный идентификатор, MAC адрес, геолокация, IMEI</li>
                    <li>Данные, получаемые через третьих лиц, такие как идентификатор социальных сетей, идентификатор магазинов мобильных приложений (Google Play, App Store), имя, указанное в социальных сетях, адрес электронной почты, список друзей,
                        получаемый через аккаунт в социальных сетях (Facebook, Google, VK и т.д.)
                    </li>
                </ul>

                <p><strong>2. Для чего мы используем собранную информацию:</strong></p>

                Обеспечение работы Сервисов и их улучшение
                <ul>
                    <li>Создание новых аккаунтов, что позволяет новым игрокам получать первоначальный доступ к Сервисам</li>
                    <li>Подтверждение и верификация платежей, производимых через Сервисы и/или внутри Сервисов</li>
                    <li>Предоставление запрашиваемых услуг и продуктов</li>
                    <li>Обеспечение внутриигровой коммуникации, такой как коммуникация пользователей между собой, в том числе посредством использования чатов, и/или коммуникация с командой технической поддержки Сервисов</li>
                    <li>Улучшение пользовательского опыта, развитие Сервисов и их кастомизация</li>
                    <li>Своевременные оповещения и предоставление информации, в том числе доставка сообщений от техподдержки, информации об обновлениях, а также оповещения системы безопасности Сервисов</li>
                </ul>

                Поддержание безопасности Сервисов
                <ul>
                    <li>Мониторинг использования Сервисов</li>
                    <li>Распознавание и отслеживание подозрительной активности</li>
                    <li>Принятие мер по отношению к мошенникам</li>
                </ul>

                Персонализация рекламы
                <ul>
                    <li>Улучшение рекламы, предоставляемой вам в соответствии с вашим поведением в сети</li>
                    <li>Анализ эффективности рекламы и ее последующая доработка</li>
                </ul>


                <p>Обращаем ваше внимание, что Azur Games в частности использует услуги, предоставляемые сервисов Smaato и его партнерами. Smaato является рекламным сервисом, предоставляемым Smaato, Inc. (US). Вы можете ознакомиться с политикой
                    конфиденциальности Smaato по ссылке <a href="https://www.smaato.com/privacy/">https://www.smaato.com/privacy/</a>. Дополнительная информация о сервисе и его партнерах доступна
                    по ссылке <a href="https://advertisingconsent.eu/vendor-list/">https://advertisingconsent.eu/vendor-list/</a>.</p>


                <p><strong>3. Как мы поддерживаем безопасность и сохранность информации</strong></p>

                <p>Мы нацелены на сохранение безопасности собираемых данных любыми доступными нам способами, следовательно, мы предприняли все необходимые меры и шаги в соответствии со стандартами индустрии, в частности, там, где это допустимо,
                    использовали шифрование, чтобы убедиться, что ваши персональные данные и транзакции защищены от неавторизованного доступа, неправомерного использования или каких-либо модификаций.</p>


                <p><strong>4. Как вы можете получить доступ, обновить или управлять вашей персональной информацией</strong></p>

                <p>В том случае, если ваша информация является неполной, просроченной, неверной, или нерелевантной для вышеуказанных целей и вы хотели бы ее модифицировать и/или внести какие-либо изменения, вы можете отправить ваш запрос на&nbsp;<a
                    href="mailto:policy@azurgames.com">policy@azurgames.com</a>. Этот электронный адрес также может быть использован в случае необходимости обжалования или наличия каких-либо вопросов касательно ваших персональных данных или
                    данной Политики Конфиденциальности.</p>


                <p><strong>5. ИНФОРМАЦИЯ ДЛЯ РЕЗИДЕНТОВ СТРАН-УЧАСТНИЦ ЕВРОПЕЙСКОГО СОЮЗА:</strong></p>


                <p>У вас есть право запросить и получить удаление ваших персональных данных, собираемых внутри наших Сервисов или через них.</p>

                <p>Если бы хотели воспользоваться данным правом, пожалуйста, отправьте ваш запрос на <a href="mailto:policy@azurgames.com">policy@azurgames.com</a> (обращаем ваше внимание, что если ваш запрос отправлен на любой другой адрес
                    электронной почты Azur Games, мы не можем гарантировать его получение и дальнейшую обработку). Мы постараемся ответить на ваш запрос в течение 30 дней с момента его получения. Для того, чтобы реализовать ваше право, нам будет
                    необходимо идентифицировать вас как собственника аккаунта. Вы осознаете и соглашаетесь, что для этих целей нам придется запросить у вас дополнительную информацию, такую как фото и/или копии ваших документов, которую вы обязуетесь
                    предоставить. Предоставление данных документов является необходимым для избежания мошенничества, а также предохранения вашего аккаунта от возможного вреда.</p>

                <p>Обращаем ваше внимание, что удаление ваших персональных данных будет сопровождаться удалением всей относящейся к ним информации, такой как ваш внутриигровой прогресс, в том числе достигнутый уровень, приобретенные лицензии на
                    внутриигровой контент (включая купленные за реальные деньги) и любой другой внутриигровой контент, связанный с вашим аккаунтом. Восстановление будет невозможным. Запрашивания удаление ваших персональных данных, вы осознаете и
                    принимаете этот факт и подтверждаете удаление всей относящейся к ним информации.</p>

                <p>Обращаем ваше внимание, что в случае, если вы прекращаете процесс удаления ваших персональных данных, вы можете по-прежнему лишиться доступа к Сервисам Azur Games.</p>

                <p>Пожалуйста, имейте в виду, что вся корреспонденция, касающаяся удаления персональных данных, ведется исключительно на английском языке.</p>


                <p><strong>5.1 Хранение данных</strong></p>

                <p>
                    Ваши данные будут храниться в нашей системе в течение всего времени, пока ваш аккаунт активен или в течение времени, необходимого для предоставления вам Сервисов. Пожалуйста, имейте в виду, что при запросе на удаление ваших
                    данных,
                    мы продолжим хранить их в виде анонимной информации после удаления вашего аккаунта. Любая информация, которой вы делились с другими пользователями наших Сервисов, такая как внутриигровые переписки, переписки со службой технической
                    поддержки или любой другой контент, доступный другим пользователям, останется доступным в системе после удаления ваших данных.</p>
            </div>
        </main>
    );
}

export default TermsOfUse;