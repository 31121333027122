// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy_link_____DXNpa{position:fixed;top:2.8rem;right:3.3rem;font-size:1.6rem;z-index:1}.policy_link_____DXNpa:hover{text-decoration:underline}@media(max-width: 767px){.policy_link_____DXNpa{position:absolute;top:5rem;right:50%;translate:50%;text-align:center}}`, "",{"version":3,"sources":["webpack://./src/components/policy_link/PolicyLink.module.scss"],"names":[],"mappings":"AACA,uBACE,cAAA,CACA,UAAA,CACA,YAAA,CACA,gBAAA,CACA,SAAA,CAEA,6BACE,yBAAA,CAGF,yBAXF,uBAYI,iBAAA,CACA,QAAA,CACA,SAAA,CACA,aAAA,CACA,iBAAA,CAAA","sourcesContent":["@import \"styles/mixins.scss\";\n._ {\n  position: fixed;\n  top: 2.8rem;\n  right: 3.3rem;\n  font-size: 1.6rem;\n  z-index: 1;\n\n  &:hover {\n    text-decoration: underline;\n  }\n\n  @media (max-width: 767px) {\n    position: absolute;\n    top: 5rem;\n    right: 50%;\n    translate: 50%;\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": `policy_link_____DXNpa`
};
export default ___CSS_LOADER_EXPORT___;
