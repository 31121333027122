// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tutorial_____QMHFD{padding:2rem 0 9rem}.tutorial__title__vGPV9{font-size:8rem;font-weight:600;text-transform:uppercase;margin-bottom:1rem}.tutorial__video__Mi63H{width:100%;margin-top:5rem}.tutorial__button__afsV0{margin:4.2rem auto 0}@media(max-width: 767px){.tutorial_____QMHFD{padding:4rem 0 5.8rem}.tutorial__title__vGPV9{font-size:3.8rem;line-height:4rem;margin-bottom:2rem}.tutorial__video__Mi63H{margin-top:3.3rem}.tutorial__button__afsV0{margin-top:2.7rem}}`, "",{"version":3,"sources":["webpack://./src/pages/home_page/tutorial/Tutorial.module.scss"],"names":[],"mappings":"AACA,oBACE,mBAAA,CAGF,wBACE,cAAA,CACA,eAAA,CACA,wBAAA,CACA,kBAAA,CAGF,wBACE,UAAA,CACA,eAAA,CAGF,yBACE,oBAAA,CAGF,yBACE,oBACE,qBAAA,CAGF,wBACE,gBAAA,CACA,gBAAA,CACA,kBAAA,CAGF,wBACE,iBAAA,CAGF,yBACE,iBAAA,CAAA","sourcesContent":["@import \"styles/mixins.scss\";\n._ {\n  padding: 2rem 0 9rem;\n}\n\n.title {\n  font-size: 8rem;\n  font-weight: 600;\n  text-transform: uppercase;\n  margin-bottom: 1rem;\n}\n\n.video {\n  width: 100%;\n  margin-top: 5rem;\n}\n\n.button {\n  margin: 4.2rem auto 0;\n}\n\n@media (max-width: 767px) {\n  ._ {\n    padding: 4rem 0 5.8rem;\n  }\n\n  .title {\n    font-size: 3.8rem;\n    line-height: 4rem;\n    margin-bottom: 2rem;\n  }\n\n  .video {\n    margin-top: 3.3rem;\n  }\n\n  .button {\n    margin-top: 2.7rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": `tutorial_____QMHFD`,
	"title": `tutorial__title__vGPV9`,
	"video": `tutorial__video__Mi63H`,
	"button": `tutorial__button__afsV0`
};
export default ___CSS_LOADER_EXPORT___;
