// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/images/back.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_page_____Z9fRp{background:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center top/cover no-repeat}.home_page__picture__rPObH{position:absolute}.home_page__picture__rPObH,.home_page__picture__rPObH img{width:100%}.home_page__online_card__uO2ja{position:absolute;width:14.3rem;right:4.5rem;top:13.6rem}@media(max-width: 767px){.home_page__online_card__uO2ja{display:none}}`, "",{"version":3,"sources":["webpack://./src/pages/home_page/HomePage.module.scss"],"names":[],"mappings":"AACA,qBACE,6EAAA,CAGF,2BACE,iBAAA,CAEA,0DACE,UAAA,CAIJ,+BACE,iBAAA,CACA,aAAA,CACA,YAAA,CACA,WAAA,CAGF,yBACE,+BACE,YAAA,CAAA","sourcesContent":["@import \"styles/mixins.scss\";\n._ {\n  background: url('assets/images/back.webp') center top  / cover no-repeat;\n}\n\n.picture {\n  position: absolute;\n\n  &, img {\n    width: 100%;\n  }\n}\n\n.online_card {\n  position: absolute;\n  width: 14.3rem;\n  right: 4.5rem;\n  top: 13.6rem;\n}\n\n@media(max-width: 767px) {\n  .online_card {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_": `home_page_____Z9fRp`,
	"picture": `home_page__picture__rPObH`,
	"online_card": `home_page__online_card__uO2ja`
};
export default ___CSS_LOADER_EXPORT___;
